<template>
  <div
    class="our-campus-accreditations"
    :class="`our-campus-accreditations-${theme}`"
  >
    <div class="row gap-y-4">
      <div class="col-lg-3">
        <h4>{{ $lang("accreditations.title") }}</h4>
      </div>
      <div class="col-lg-3" :class="{ 'offset-lg-3': noCambridge }">
        <img
          src="/imgs/logo_w_cervantes.png"
          alt="logo_nlc_cervantes"
          height="65"
        />
        <p class="small">{{ $lang("accreditations.cervantes") }}</p>
      </div>
      <div class="col-lg-3">
        <img
          src="/imgs/logo_dele_siele.png"
          alt="logo_dele_siele"
          height="65"
        />
        <p class="small">{{ $lang("accreditations.dele_siele") }}</p>
      </div>
      <div class="col-lg-3" v-if="!noCambridge">
        <img src="/imgs/logo_cambridge.png" alt="logo_cambridge" height="65" />
        <p class="small">{{ $lang("accreditations.cambridge") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "tertiary",
    },
    noCambridge: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
